import { Button } from "@mui/material";
import {
  EMERGENCY_PHONE_NUMBER_STRING,
  PHONE_NUMBER_STRING,
} from "../../../services/const/links";
import styles from "./InfoTable.module.scss";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/firebase";

const InfoTable: React.FC = () => {
  return (
    <section className={styles.container + " inverted"}>
      <div className={styles.bookAppointment}>
        <h2>Book Appointment</h2>

        <p>
          We put your smile at our priority.
          <br />
          Believe. Expectation. Reality.
        </p>

        <Button
          href={"tel://" + PHONE_NUMBER_STRING}
          rel="noreferrer"
          variant="outlined"
          color="info"
          onClick={() => {
            logEvent(analytics, "book_appointment_call", {
              source: "info_table",
            });
          }}
        >
          Book Now 🤝
        </Button>
      </div>

      <div className={styles.hours}>
        <h2>Operating Hours</h2>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>Monday - Thursday</div>
          <div style={{ textAlign: "right" }}>
            <div>8:30 AM - 1:30 PM</div>
            <div>3:30PM - 6:30 PM</div>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ flex: 1 }}>Friday</div>
          <div style={{ textAlign: "right" }}>
            <div>8:30 AM - 3 PM</div>
          </div>
        </div>

        <hr style={{ marginTop: 15, marginBottom: 15 }} />

        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>Saturday - Sunday</div>

          <div style={{ textAlign: "right" }}>closed</div>
        </div>
      </div>

      <div className={styles.emergency}>
        <h2>Emergencies</h2>

        <a href={"tel://" + EMERGENCY_PHONE_NUMBER_STRING} rel="noopener">
          +357 99638968
        </a>

        <p>
          In the event of an emergency (urgent cases), please call us on this
          number.
        </p>
      </div>
    </section>
  );
};

export default InfoTable;

import { TeamMember } from "../../types";

const teamMembers: TeamMember[] = [
  {
    title: "Dr.",
    name: "George Pashias",
    description:
      "Graduated the Dental school of Athens university in 1990 and running his own dental clinic in Limassol ever since.\nIn 1992 completed the post-graduate courses in dental implants in Germany. More than 5000 implants were placed since 1992 giving him the experience of operating any case of dental implants.",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Fdr-george-pashias.webp?alt=media&token=f97a93e2-78a1-417a-af1e-cc8cfc8d2ed6",
  },
  {
    title: "Dr.",
    name: "Maria Kountouridou",
    description: "Dentist",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Fdr-maria-kountouridou.webp?alt=media&token=a9e7eeea-a4cd-4a41-8dfe-e30a9160ce0a",
  },
  {
    title: "Dr.",
    name: "Alexis Pashias",
    description: "Periodontist",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Fdr-alexis-pashias.webp?alt=media&token=14b3fbfc-4d01-41fb-9324-d62c8b83b7f0",
  },
  {
    title: "Dr.",
    name: "Panagiotis Michailidis",
    description: "Orthodontist",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Fdr-panagiotis-michailidis.webp?alt=media&token=e3dd8dc7-6242-423a-bf4d-8f5ba414cad7",
  },
  {
    title: "Dr.",
    name: "Milena Azoidou",
    description: "Dentist",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Fmilena-azoidou.webp?alt=media&token=bdd3c6b0-ad1c-4c7c-9557-18c0b9be5d3d",
  },
  // {
  //   title: "",
  //   name: "Andriana Iosifaki",
  //   description: "Dental assistant",
  //   imageURL:
  //     "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Fandriana-iosifaki.webp?alt=media&token=da3fd88b-6c3c-4bdd-858a-5531381a4fc8",
  // },
  {
    title: "",
    name: "Marina Avgousti",
    description:
      "Dental assistant with 15 years of experience, always by your side to make you feel comfortable and safe.",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Fmarina-avgousti.webp?alt=media&token=eb626316-73f0-4d08-8134-5ec1125d157c",
  },
  {
    title: "",
    name: "Maria Leantzi",
    description: `Maria has been working as a dental assistant for ${
      new Date().getFullYear() - 1996
    } years. In her own words: 'I do this job not because I have to, but because I love it! I like the communication with the patients who see me as a friend because I help them to overcome their fear of the dentist.'`,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Fmaria-leantzi.webp?alt=media&token=2f2105b6-c47d-432c-abe8-a6d327f0bab9",
  },
  {
    title: "",
    name: "Maria Katerou",
    description: "Administrator",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Fmaria-katerou.webp?alt=media&token=384af3c6-1513-4aca-b9b1-8124b1f298d4",
  },
  {
    title: "",
    name: "Irina Pashia",
    description: "Coordinator",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fteam%2Firina-kenina-pashia.webp?alt=media&token=dc12a292-a7e8-4745-9eb8-667ec415ef30",
  },
];

export default teamMembers;

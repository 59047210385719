import { Button, TextField } from "@mui/material";
import styles from "./index.module.scss";
import { useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

interface CallbackFormProps {
  onFormStart: () => void;
  onFormSubmit: () => void;
}

export const CALLBACK_FORM_NAME = "callback_form";
const CALLBACK_PHONE_INPUT_ID = "callback-phone";

const CallbackForm: React.FC<CallbackFormProps> = (props) => {
  const [formStarted, setFormStarted] = useState(false);

  const onInputFocus = () => {
    if (!formStarted) {
      setFormStarted(true);

      logEvent(analytics, "form_start", {
        form_name: "callback_form",
      });

      props.onFormStart();
    }
  };

  return (
    <form
      action="https://formbold.com/s/oP0ao"
      method="POST"
      className={styles.form_container}
      onSubmit={() => {
        logEvent(analytics, "form_submission", {
          form_name: CALLBACK_FORM_NAME,
          phone_number: (
            document.getElementById(CALLBACK_PHONE_INPUT_ID) as HTMLInputElement
          )?.value,
        });

        setFormStarted(false);

        props.onFormSubmit();
      }}
    >
      <TextField
        name="name"
        variant="standard"
        label="Name"
        required
        className={styles.input}
        onFocus={onInputFocus}
      />

      <TextField
        id={CALLBACK_PHONE_INPUT_ID}
        name="phone"
        variant="standard"
        label="Phone Number"
        required
        className={styles.input}
      />

      <TextField
        name="message"
        variant="standard"
        label="Message"
        className={styles.input}
      />

      <Button
        variant="contained"
        style={{
          marginTop: 30,
        }}
        type="submit"
      >
        Submit Request
      </Button>
    </form>
  );
};

export default CallbackForm;

import { Button } from "@mui/material";
import Card from "../../../Card";
import { PhoneCall } from "react-feather";
import AvatarRow from "../../../AvatarRow";
import DrGeorgeMinPNG from "../../../../assets/img/dr-george-min.png";
import { PHONE_NUMBER_STRING } from "../../../../services/const/links";
import { scrollToElement } from "../../../../services/utils/common";
import { doctorElementID } from "../../Doctor";

interface IProps {
  className?: string;
}

const MeetCard: React.FC<IProps> = (props) => {
  return (
    <Card
      style={{
        justifyContent: "center",
        width: 300,
        paddingTop: 35,
        paddingBottom: 30,
      }}
      className={props.className}
    >
      <h2
        style={{ marginBottom: 20, cursor: "pointer" }}
        onClick={() => scrollToElement(doctorElementID)}
      >
        Meet the doctor
      </h2>

      <hr />

      <AvatarRow
        name="Dr. George Pashias"
        aboutText="dentist - implantologist"
        src={DrGeorgeMinPNG}
        containerStyle={{
          marginTop: 16,
          marginBottom: 10,
        }}
        onClick={() => scrollToElement(doctorElementID)}
      />

      <Button
        href={"tel://" + PHONE_NUMBER_STRING}
        rel="noreferrer"
        variant="contained"
        style={{ marginTop: 10, marginBottom: 10 }}
        startIcon={<PhoneCall />}
      >
        Contact the Clinic
      </Button>
    </Card>
  );
};

export default MeetCard;

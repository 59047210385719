import styles from "./TheTeam.module.scss";
import { Box, Button, Typography } from "@mui/material";
import Image from "../../Image";
import { teamImageURL } from "../../../services/const/images";
import { ArrowDown, ArrowRight } from "react-feather";
import { scrollToElement } from "../../../services/utils/common";
import { teamMembersElementID } from "../../TeamMembers";
import { Link } from "react-router-dom";

interface TheTeamProps {
  type?: "scroll" | "navigate";
}

const TheTeam: React.FC<TheTeamProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Image
        fade
        alt="The dental clinic team standing all together side by side"
        src={teamImageURL}
        className={styles.TeamImage}
        style={{ aspectRatio: "3 / 2" }}
      />

      <Typography
        variant="h5"
        style={{
          textAlign: "center",
          marginTop: 20,
        }}
      >
        Meet our caring team
      </Typography>

      <Typography
        variant="body2"
        style={{
          textAlign: "center",
          marginBottom: 20,
          maxWidth: 600,
        }}
      >
        At our dental clinic, we believe that our team is the heart and soul of
        our practice. We take pride in not only offering a wide range of dental
        specialties but also in having a warm and approachable team who are
        dedicated to assisting you with any inquiries you may have.
      </Typography>

      {props.type === "scroll" ? (
        <Button
          startIcon={<ArrowDown />}
          variant="contained"
          onClick={() => {
            scrollToElement(teamMembersElementID);
          }}
        >
          Meet the team
        </Button>
      ) : props.type === "navigate" ? (
        <>
          <Button component={Link} to="/team" endIcon={<ArrowRight />}>
            Meet the team
          </Button>
        </>
      ) : null}
    </Box>
  );
};

export default TheTeam;

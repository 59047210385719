import { room3ChairImageURL } from "./clinic";

const techImages = [
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fcase-1-12-2023.webp?alt=media&token=727ebc0f-829e-495d-b259-687a3edd1250",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Fhallway-1.webp?alt=media&token=da5eb038-99e8-4646-9138-30597cb3b7cc",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-room-1-chair-tv.webp?alt=media&token=7e199c6a-7deb-4ff1-b5fa-b5b8c070d5d8",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-room-1-lamp.webp?alt=media&token=b5bd0810-665f-4968-b8a8-567561340cbb",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-room-1.webp?alt=media&token=5fa3508b-3854-43bb-8f5c-43a3247ae331",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-room-1-microscope.webp?alt=media&token=a92b7b6b-8eeb-46a6-8dcd-b7ba840dc320",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-glasses.webp?alt=media&token=5474d837-7893-4ec0-bbf4-225120476f2b",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-computer.webp?alt=media&token=18c536d9-cc51-4406-9615-fda718944965",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-cerec-2.webp?alt=media&token=93130188-f82a-4171-b423-3e3336a316b3",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-cerec-3.webp?alt=media&token=3ecc7227-d3ba-4f04-ba26-a4c618a338a0",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Ftech-camera-2.webp?alt=media&token=78b72936-6766-4b25-85ae-6cd72c30aa6e",
  "https://firebasestorage.googleapis.com/v0/b/pashias-dental.appspot.com/o/images%2Foutside.webp?alt=media&token=e025a2a1-694d-4843-b70b-a2d8e43e8747",
  room3ChairImageURL,
  "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftech3.png?alt=media&token=7bc87795-3cf3-4ca3-9d58-8cbc92b641aa",
  "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftech4.png?alt=media&token=93e5ae99-8276-4758-92f2-9bee86f05bad",
  "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftech1.png?alt=media&token=93416a95-3474-4292-aa21-1c23ffe3f8d9",
  "https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Ftoly%2Fprojects%2Fpashias-dental%2Ftech2.png?alt=media&token=7963b9da-3114-4a89-8cda-380e664924cc",
];

export default techImages;

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ServiceCard from "../../components/home/Services/ServiceCard";
import services from "../../services/const/services";
import styles from "./Treatments.module.scss";
import { ChevronDown } from "react-feather";
import Image from "../../components/Image";
import PageHeader from "../../components/PageHeader";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

const Treatments: React.FC = () => {
  const [searchParams] = useSearchParams();

  const selectedServiceTitle = searchParams.get("t");

  const selectedServiceIndex = !selectedServiceTitle
    ? 0
    : services.findIndex(
        (x) =>
          encodeURIComponent(x.title) ===
          encodeURIComponent(selectedServiceTitle)
      );

  useEffect(() => {
    if (!selectedServiceTitle) {
      return;
    }

    const element = document.getElementById(
      "aboutService-" + selectedServiceIndex
    );

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [selectedServiceIndex, selectedServiceTitle]);

  return (
    <>
      <PageHeader>Our Dental Care Services</PageHeader>
      <section className={styles.services}>
        {services.map((service, index) => {
          const encodedTitle = encodeURIComponent(service.title);

          return (
            <ServiceCard
              key={"service" + index}
              service={service}
              selected={
                (!selectedServiceTitle && index === 0) ||
                encodeURIComponent(selectedServiceTitle) === encodedTitle
              }
              onClick={() => {
                logEvent(analytics, "servce_selected", {
                  service: service.title,
                });
              }}
              containerStyle={{ flex: 1 }}
              size="small"
            />
          );
        })}
      </section>

      {services.map((service, index) => {
        return (
          <div
            style={{
              display: index === selectedServiceIndex ? "flex" : "none",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <section
              id={"aboutService-" + index}
              className={styles.aboutService}
            >
              <div className={styles.aboutServiceInfo}>
                <h2>{service.title}</h2>

                <p>{service.description}</p>

                {service.jsx ? (
                  <div className={styles.aboutServiceCustom}>
                    <service.jsx />
                  </div>
                ) : null}
              </div>
              <div>
                <Image
                  fade
                  alt="treatment representation"
                  className={styles.aboutServiceImage}
                  src={service.imageURL}
                />
              </div>
            </section>

            <section className={styles.faq}>
              {service.faq
                ? service.faq.map((qna, index) => (
                    <Accordion
                      key={"qna" + qna.question + index}
                      style={
                        index === 0
                          ? {
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                            }
                          : index === service.faq.length - 1
                          ? {
                              borderBottomLeftRadius: 10,
                              borderBottomRightRadius: 10,
                            }
                          : {}
                      }
                    >
                      <AccordionSummary expandIcon={<ChevronDown />}>
                        <h3>{qna.question}</h3>
                      </AccordionSummary>

                      <AccordionDetails>
                        <p>{qna.answer}</p>
                      </AccordionDetails>
                    </Accordion>
                  ))
                : null}
            </section>
          </div>
        );
      })}
    </>
  );
};

export default Treatments;

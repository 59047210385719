import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBdQx06RpXNRt5TPIywxgMVX3d2-0lF6ng",
  authDomain: "pashias-dental.firebaseapp.com",
  projectId: "pashias-dental",
  storageBucket: "pashias-dental.appspot.com",
  messagingSenderId: "1047918094613",
  appId: "1:1047918094613:web:3d665f7e42e65c37b3611c",
  measurementId: "G-GLZSMWKLX4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };

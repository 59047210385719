import styles from "./Services.module.scss";
import services from "../../../services/const/services";
import ServiceCard from "./ServiceCard";

const Services: React.FC = () => {

  return (
    <section className={styles.container}>
      <h1>Our dental care services</h1>

      <p>
        Our clinic offers all standard dental treatments, such as cleaning and
        polish, fillings, root-canal treatment, tooth removals and prosthetic
        restorations fixed or removable.
      </p>

      <div className={styles.cards}>
        {services.map((service, index) => (
          <ServiceCard
            key={"service" + index}
            service={service}
            onClick={() => {
              window.scrollTo({ top: 0 });
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default Services;

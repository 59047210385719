import { Button } from "@mui/material";
import Card from "../../components/Card";
import Map from "../../components/Map";
import DirectionsSVG from "../../assets/icons/location.svg";
import { GOOGLE_MAPS_LINK } from "../../services/const/links";
import styles from "./Contact.module.scss";
import InfoTable from "../../components/home/InfoTable";
import CallCard from "./CallCard";
import { contactImageURL } from "../../services/const/images";
import PageHeader from "../../components/PageHeader";
import ImageWithCaption from "../../components/ImageWithCaption";
import { outside } from "../../services/const/images/clinic";
import { ArrowRight } from "react-feather";
import { useEffect, useRef } from "react";
import CallbackForm from "../../components/CallbackForm";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";
import { Link } from "react-router-dom";

const Contact: React.FC = () => {
  const callbackFormSubmitted = useRef(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    logEvent(analytics, "page_view", {
      page_title: "Contact",
      page_path: "/contact",
      page_location: window.location.href,
    });
  }, []);

  return (
    <>
      <PageHeader>Contact</PageHeader>

      <section className={styles.map}>
        <div className={styles.mapContainer}>
          <Map />
        </div>

        <Card className={styles.mapCard}>
          <h2>Find us</h2>
          <hr style={{ marginTop: 6 }} />
          <p>
            127 Vasileos Georgiou, 4048
            <br /> Limassol, Cyprus
          </p>

          <Button
            href={GOOGLE_MAPS_LINK}
            target="_blank"
            rel="noopener"
            variant="outlined"
            onClick={() => {
              logEvent(analytics, "get_directions", {
                source: "map_card",
              });
            }}
          >
            Get Directions
          </Button>

          <div className={styles.directionsIcon}>
            <img alt="directions" src={DirectionsSVG} />
          </div>
        </Card>
      </section>

      <section className={styles.contact}>
        <div className={styles.contactLeft}>
          <h1>
            You have any questions?
            <br />
            Request a callback.
          </h1>

          <p>We will call you as soon as possible.</p>

          <CallbackForm
            onFormStart={() => {}}
            onFormSubmit={() => {
              callbackFormSubmitted.current = true;
            }}
          />
        </div>

        <div className={styles.contactRight}>
          <img
            alt="contact background"
            src={contactImageURL}
            className={styles.graphicImage}
          />

          <CallCard className={styles.contactCard} />
        </div>
      </section>

      <InfoTable />

      <ImageWithCaption
        containerStyle={{
          marginTop: 6,
        }}
        alt="outside view of the clinic, to help with finding it"
        src={outside}
        title="By the sea"
        description="Find us by the sea, in Limassol"
        direction="row-reverse"
        imageBorderRadius={100}
      >
        <Button
          component={Link}
          to="/clinic"
          endIcon={<ArrowRight />}
          sx={{ margin: 2 }}
          onClick={() => {
            logEvent(analytics, "page_view", {
              page_title: "Clinic",
              page_path: "/clinic",
              page_location: window.location.href,
              callback_form_submitted: callbackFormSubmitted.current,
            });
          }}
        >
          See the clinic
        </Button>
      </ImageWithCaption>
    </>
  );
};

export default Contact;

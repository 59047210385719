import { Button } from "@mui/material";
import { PhoneCall } from "react-feather";
import Card from "../../../components/Card";
import { PHONE_NUMBER_STRING } from "../../../services/const/links";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/firebase";

interface CallCardProps {
  className?: string;
}

const CallCard: React.FC<CallCardProps> = (props) => {
  return (
    <Card
      style={{
        justifyContent: "center",
        width: 350,
        paddingTop: 35,
        paddingBottom: 30,
      }}
      className={props.className}
    >
      <h2>Book appointment</h2>

        <Button
          href={"tel://" + PHONE_NUMBER_STRING}
          rel="noreferrer"
          variant="contained"
          style={{ marginTop: 16 }}
          startIcon={<PhoneCall />}
          onClick={() => {
            logEvent(analytics, "call", {
              source: "call_card",
            })
          }}
        >
          +357 25 346060
        </Button>
    </Card>
  );
};

export default CallCard;
